import { Divider } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { logoutUser, openModal } from 'app/auth/store/userSlice';
import routes, { GetGeneralSettings } from 'hooks/api';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import QueryHook from 'hooks/queryHook';
import jwtService from 'app/services/jwtService';
import { setGeneralSettings } from '../../store/generals/index';

function UserMenu(props) {
	const accounts = JSON.parse(localStorage.getItem('accounts') || '{}');
	const accountKeys = Object.keys(accounts);

	const { data: settingsData } = GetGeneralSettings({
		take: 10,
		page: 1
	});

	const dispatch = useDispatch();

	const { navigate } = QueryHook();

	const user = useSelector(({ auth }) => auth.user);

	const [userMenu, setUserMenu] = useState(null);

	const userMenuClick = event => {
		setUserMenu(event.currentTarget);
	};

	const userMenuClose = () => {
		setUserMenu(null);
	};

	useEffect(() => {
		if (settingsData?.data?.data) {
			dispatch(setGeneralSettings(settingsData?.data?.data));
		}
	}, [setGeneralSettings?.data?.data, dispatch]);

	return (
		<>
			<Button className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6" onClick={userMenuClick}>
				<div className="hidden md:flex flex-col mx-4 items-end">
					<Typography component="span" className="font-semibold flex">
						{user?.fullName}
					</Typography>
					<Typography className="text-11 font-medium capitalize" color="textSecondary">
						{user?.role?.toString()}
						{(!user?.role || (Array.isArray(user?.role) && user?.role?.length === 0)) && 'Guest'}
					</Typography>
				</div>
				<Avatar
					className="md:mx-4"
					alt="user photo"
					src={
						user?.image && user?.image !== ''
							? `${routes.publicImages}/${user?.image}`
							: 'assets/images/avatars/default.png'
					}
				/>
			</Button>

			<Popover
				open={Boolean(userMenu)}
				anchorEl={userMenu}
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				classes={{
					paper: 'py-8'
				}}
			>
				{!user?.role || user?.role?.length === 0 ? (
					<>
						<MenuItem component={Link} to="/login" role="button">
							<ListItemIcon className="min-w-40">
								<Icon>lock</Icon>
							</ListItemIcon>
							<ListItemText primary="Login" />
						</MenuItem>
					</>
				) : (
					<>
						<MenuItem component={Link} to="/pages/profile" onClick={userMenuClose} role="button">
							<ListItemIcon className="min-w-40">
								<Icon>account_circle</Icon>
							</ListItemIcon>
							<ListItemText primary="Мой профиль" />
						</MenuItem>

						<Divider />

						{accountKeys
							?.filter(phone => phone !== user?.phoneNumber)
							?.map(phone => (
								<MenuItem
									key={phone}
									onClick={() => {
										navigate.push('/', { replace: true });
										jwtService.switchAccount(phone);
									}}
									role="button"
								>
									<ListItemIcon className="min-w-40">
										<Icon>person</Icon>
									</ListItemIcon>
									<ListItemText primary={`${accounts[phone]?.fullName}`} />
									<ListItemText primary={`(${accounts[phone]?.role})`} />

									<ListItemIcon
										className="min-w-40 ml-5"
										onClick={e => {
											e.stopPropagation();
											jwtService.logoutWithApi(accounts[phone]?.id);
											jwtService.removeAccount(phone);
										}}
									>
										<Icon color="error">clear</Icon>
									</ListItemIcon>
								</MenuItem>
							))}

						<Divider />

						<MenuItem
							onClick={() => {
								dispatch(openModal());
								userMenuClose();
							}}
							role="button"
						>
							<ListItemIcon className="min-w-40">
								<Icon>person_add</Icon>
							</ListItemIcon>
							<ListItemText primary="Добавить аккаунт" />
						</MenuItem>

						<MenuItem
							onClick={() => {
								dispatch(logoutUser());
								userMenuClose();
							}}
						>
							<ListItemIcon className="min-w-40">
								<Icon>exit_to_app</Icon>
							</ListItemIcon>
							<ListItemText primary="Выйти" />
						</MenuItem>
					</>
				)}
			</Popover>
		</>
	);
}

export default UserMenu;

import { authRoles } from 'app/auth';
import { lazy } from 'react';

const DataAppConfig = {
	settings: {
		layout: {}
	},
	auth: [...authRoles.admin, ...authRoles.productModerator, ...authRoles.warehouseMan],
	routes: [
		{
			path: '/apps/warehouses/:dataID',
			exact: true,
			component: lazy(() => import('./single/Data'))
		},
		{
			path: '/apps/warehouses',
			exact: true,
			component: lazy(() => import('./list/Datas'))
		},
		{
			path: '/apps/warehouses/:dataID/returned-products/:groupId',
			exact: true,
			component: lazy(() => import('./single/tabs/ReturnedProductsForModerator/SingleReturnstDetail/Datas'))
		},
		{
			path: '/apps/warehouses/:dataID/warehouseTransfers/:transferID',
			exact: true,
			component: lazy(() => import('./single/tabs/WarehouseTransfer/SingleTransferGroup/index'))
		},
		{
			path: '/apps/warehouses/:dataID/imports/:importId',
			exact: true,
			component: lazy(() => import('./single/tabs/ImportsForModerator/SingleImportDetail/index'))
		}
	]
};

export default DataAppConfig;

import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import routes from 'hooks/api';
import { api } from 'hooks/api/main-request/base';
import { isReady } from './isReady';
/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
	init() {
		this.getLoginnedUsers();
		this.handleAuthentication();

		window.addEventListener('beforeunload', () => {
			const remainingTabs = Object.keys(sessionStorage).length;
			if (remainingTabs === 1) {
				const lastAccount = JSON.parse(sessionStorage.getItem('activeAccount'));
				if (lastAccount) {
					this.setLastActiveAccount(lastAccount?.phoneNumber);
				}
			}
		});
	}

	handleAuthentication = () => {
		const activeAccount = sessionStorage.getItem('activeAccount');

		if (!activeAccount) {
			const lastActiveAccount = localStorage.getItem('lastActiveAccount');
			if (lastActiveAccount) {
				sessionStorage.setItem('activeAccount', lastActiveAccount);
			}
		}

		setTimeout(() => {
			isReady.resolve();
		}, 1200);

		const userId = this.getUserId();

		if (!userId) {
			this.emit('onNoAccessToken');

			return;
		}

		this.emit('onAutoLogin', true);
	};

	signInWithPhoneNumberAndPassword = (phoneNumber, password, oneTimePassword) => {
		return new Promise((resolve, reject) => {
			axios
				.post(
					routes.signIn,
					{
						phoneNumber: String(phoneNumber)?.trim(),
						password,
						oneTimePassword
					},
					{
						withCredentials: true
					}
				)
				.then(response => {
					const { id, role, phoneNumber, fullName } = response?.data;

					if (response.data) {
						const newAccount = {
							id,
							role,
							fullName,
							phone: phoneNumber
						};
						this.addAccount(newAccount);
						sessionStorage.setItem('activeAccount', JSON.stringify(newAccount));
						this.setLastActiveAccount(phoneNumber);

						const xsrfToken = response.headers['xsrf-token'];
						const tokens = JSON.parse(localStorage.getItem('xsrf-tokens') || '{}');

						if (xsrfToken) {
							tokens[id] = xsrfToken;
							localStorage.setItem('xsrf-tokens', JSON.stringify(tokens));
						}

						window.location.reload();
					} else {
						reject(response.data.error);
					}
				})
				.catch(err => {
					reject(err);
				});
		});
	};

	signInWithToken = () => {
		return new Promise((resolve, reject) => {
			const UserId = this.getUserId();

			api.get(routes.accessToken, {
				headers: {
					common: {
						UserId
					}
				}
			})
				.then(response => {
					if (response.data) {
						resolve(response.data);
					} else {
						// this.logoutWithApi(UserId);
						reject(new Error('Failed to login with token.'));
					}
				})
				.catch(() => {
					// this.logoutWithApi(UserId);
					reject(new Error('Failed to login with token.'));
				});
		});
	};

	getLoginnedUsers = () => {
		return new Promise((resolve, reject) => {
			axios
				.get(routes.getLoginnedUsers, { withCredentials: true })
				.then(response => {
					const accountsMap = {};
					const lastActiveAccount = JSON.parse(localStorage.getItem('lastActiveAccount'));
					const activeAccount = JSON.parse(sessionStorage.getItem('activeAccount'));
					const isExist = response?.data?.find(account => account?.id === activeAccount?.id);

					if (response?.data?.length === 0) {
						localStorage.clear();
						sessionStorage.clear();

						return;
					}

					response?.data?.forEach(account => {
						accountsMap[account?.phoneNumber] = {
							id: account?.id,
							role: account?.role,
							fullName: account?.fullName,
							phone: account?.phoneNumber
						};
					});

					localStorage.setItem('accounts', JSON.stringify(accountsMap));

					if (!isExist) {
						sessionStorage.setItem('activeAccount', JSON.stringify(lastActiveAccount));
						this.setLastActiveAccount(response.data[0]?.phoneNumber);
					}

					resolve(response);
				})
				.catch(err => {
					reject(err);
				});
		});
	};

	checkActiveAccount = phone => {
		const accounts = JSON.parse(localStorage.getItem('accounts') || '{}');
		const activeAccount = JSON.parse(sessionStorage.getItem('activeAccount'));

		if (accounts[phone] && accounts[phone]?.id === activeAccount?.id) {
			delete accounts[phone];
			localStorage.setItem('accounts', JSON.stringify(accounts));
			this.setLastActiveAccount(Object.values(accounts)[0]?.phone);
			sessionStorage.setItem('activeAccount', JSON.stringify(Object.values(accounts)[0]));

			console.log('Account found!');
		} else {
			console.log('Account not found!');
		}
	};

	getAdminOTP = phoneNumber => {
		return new Promise((resolve, reject) => {
			axios
				.get(`${routes.getOTP}?phone=${String(phoneNumber)?.trim()}`, { withCredentials: true })
				.then(response => {
					resolve(response);
				})
				.catch(err => {
					reject(err);
				});
		});
	};

	setSession = access_token => {
		if (access_token) {
			sessionStorage.setItem('access_token', access_token);
			axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
		} else {
			sessionStorage.removeItem('access_token');
			delete axios.defaults.headers.common.Authorization;
		}
	};

	setRefreshToken = refresh_token => {
		if (refresh_token) {
			sessionStorage.setItem('refresh_token', refresh_token);
		} else {
			sessionStorage.removeItem('refresh_token');
		}
	};

	addAccount = newAccount => {
		const accounts = JSON.parse(localStorage.getItem('accounts') || '{}');

		accounts[newAccount?.phone] = newAccount;
		localStorage.setItem('accounts', JSON.stringify(accounts));

		console.log(`Account for phone ${newAccount?.phone} has been added or updated.`);
	};

	switchAccount = phone => {
		const accounts = JSON.parse(localStorage.getItem('accounts') || '{}');
		const selectedAccount = accounts[phone];

		this.setLastActiveAccount(phone);

		if (selectedAccount) {
			sessionStorage.setItem('activeAccount', JSON.stringify(selectedAccount));
			window.location.reload();
			console.log(`Switched to account: ${selectedAccount?.name}`);
		} else {
			console.log('Account not found!');
		}
	};

	removeAccount = phone => {
		const accounts = JSON.parse(localStorage.getItem('accounts') || '{}');

		if (accounts[phone]) {
			delete accounts[phone];
			localStorage.setItem('accounts', JSON.stringify(accounts));

			console.log('Account removed successfully!');
		} else {
			console.log('Account not found!');
		}
	};

	setLastActiveAccount = phone => {
		const accounts = JSON.parse(localStorage.getItem('accounts') || '{}');
		const selectedAccount = accounts[phone];

		if (selectedAccount) localStorage.setItem('lastActiveAccount', JSON.stringify(selectedAccount));
	};

	clearAccounts = () => {
		localStorage.removeItem('accounts');
		localStorage.removeItem('activeAccount');
		localStorage.removeItem('lastActiveAccount');
		sessionStorage.clear();
	};

	logout = () => {
		this.setSession(null);
		this.setRefreshToken(null);
		this.clearAccounts();
	};

	logoutWithApi = UserId => {
		return new Promise((resolve, reject) => {
			api.get(routes.logout, {
				headers: {
					common: {
						UserId
					}
				}
			})
				.then(response => {
					const activeAccount = JSON.parse(sessionStorage.getItem('activeAccount'));

					this.removeAccount(activeAccount?.phone);
					this.getLoginnedUsers();
					resolve(response);
				})
				.catch(err => {
					reject(err);
				})
				.finally(() => {
					window.location.reload();
				});
		});
	};

	getAccessToken = () => {
		return JSON.parse(sessionStorage.getItem('activeAccount'))?.access_token;
	};

	getRefreshToken = () => {
		return JSON.parse(sessionStorage.getItem('activeAccount'))?.refresh_token;
	};

	getUserId = () => {
		return JSON.parse(sessionStorage.getItem('activeAccount'))?.id;
	};
}

const instance = new JwtService();

export default instance;

import { isReady } from 'app/services/jwtService/isReady';
import axios from 'axios';
import { getXSRFToken } from 'utils/getXSRFToken';
import { setXSRFToken } from 'utils/setXSRFToken';

function newAbortSignal(timeoutMs) {
	const abortController = new AbortController();
	setTimeout(() => abortController.abort(), timeoutMs || 0);
	return abortController.signal;
}

export const api = axios.create({
	baseURL: localStorage.getItem('baseUrl') || process.env.REACT_APP_API_URL,
	withCredentials: true
});

let isRequestInProgress = false;
const requestQueue = [];

const processNextRequest = () => {
	if (requestQueue.length > 0) {
		const nextRequest = requestQueue.shift();
		if (nextRequest) nextRequest();
	} else {
		isRequestInProgress = false;
	}
};

api.interceptors.request.use(
	async config => {
		await isReady.wait();

		return new Promise(resolve => {
			const executeRequest = () => {
				isRequestInProgress = true;
				config.headers['XSRF-TOKEN'] = getXSRFToken();

				if (config.method === 'get') {
					config.signal = newAbortSignal(10000);
				}

				if (String(config.headers.Authorization).toLowerCase() === 'basic') {
					config.headers.Authorization = 'Basic ZnN0ZmR1c2VyOmZzdGZkcHN3ZA==';
				} else {
					const UserId = JSON.parse(sessionStorage.getItem('activeAccount'))?.UserId;
					if (UserId) {
						config.headers.UserId = UserId;
					}
				}

				resolve(config);
			};

			if (isRequestInProgress) {
				requestQueue.push(executeRequest);
			} else {
				executeRequest();
			}
		});
	},
	error => {
		return Promise.reject(error);
	}
);

api.interceptors.response.use(
	response => {
		const xsrfToken = response.headers['xsrf-token'];

		setXSRFToken(xsrfToken);

		isRequestInProgress = false;
		processNextRequest();

		return response;
	},
	error => {
		const xsrfToken = error?.response?.headers['xsrf-token'];

		setXSRFToken(xsrfToken);

		isRequestInProgress = false;
		processNextRequest();

		if (error?.response?.status === 406) {
			throw error;
		}

		if (error?.response) {
			const requestHeaders = error.config?.headers;

			// if (!error.config.url.includes('logger')) {
			// 	sendApiErrorToBackend(error, requestHeaders);
			// }

			return handleError(error?.response, xsrfToken);
		}

		throw error;
	}
);

// Error handling function
function handleError(response, token) {
	const { status, data } = response;

	if (status && status >= 400 && status <= 600) {
		const messages = data?.message || data?.error;

		// temporary: lougout only one user with status 418
		if ((status === 418 || status === 426) && token) {
			localStorage.clear();
			sessionStorage.clear();
			window.location.reload();

			// store.dispatch(logoutUser());
		}

		if (typeof messages === 'string' || Array.isArray(messages)) {
			if (Array.isArray(messages)) {
				const [message] = messages;

				throw new Error(message);
			}

			throw new Error(messages);
		}

		throw Error(String(response));
	}

	if ('data' in response) return response;
}

async function sendApiErrorToBackend(error, requestHeaders) {
	const { status, data, config } = error.response;

	const errorPayload = {
		message: JSON.stringify(data?.message) || 'Unknown API Error',
		name: 'API Error',
		stack: error.stack,
		path: config.url,
		extra: JSON.stringify({
			method: config.method,
			timestamp: new Date().toISOString(),
			status,
			requestHeaders
		})
	};

	try {
		await api({
			method: 'POST',
			url: 'logger',
			data: JSON.stringify(errorPayload),
			headers: { 'Content-Type': 'application/json', 'XSRF-TOKEN': undefined }
		});
	} catch (err) {
		console.error('Failed to send API error to backend:', err);
	}
}

import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { submitLogin } from 'app/auth/store/loginSlice';
import _ from '@lodash';
import { useTranslation } from 'react-i18next';
import { AllChemas } from 'app/utils/schemaList';
import { useToast } from 'hooks/useToast';
import JwtService from 'app/services/jwtService';
import * as yup from 'yup';

const defaultValues = {
	phoneNumber: '',
	password: ''
};

function JWTLoginTab() {
	const { t } = useTranslation();
	const [showPassword, setShowPassword] = useState(false);
	const [showOTP, setShowOTP] = useState(false);
	const { showToast } = useToast();

	const dispatch = useDispatch();
	const login = useSelector(({ auth }) => auth.login);
	const { watch, control, formState, handleSubmit } = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(AllChemas({})?.loginSchema)
	});
	const { isValid, dirtyFields, errors } = formState;
	const values = watch();

	function onSubmit(model) {
		dispatch(submitLogin(model));
	}

	useEffect(() => {
		if (login?.errors?.response?.data?.message) {
			showToast(login?.errors?.response?.data?.message, 'error');
		}
	}, [login?.errors?.response?.data?.message, dispatch]);

	const phoneSchema = yup
		.string()
		.required('Please enter your phone number')
		.min(13, 'Login is too short - should be 13 chars minimum.')
		.max(13, 'Login is too long - should be 13 chars maximum.');

	useEffect(() => {
		if (values?.phoneNumber) {
			phoneSchema.isValid(values.phoneNumber).then(isValid => {
				if (!isValid) {
					console.error('Phone validation failed');
					return;
				}

				JwtService.getAdminOTP(values.phoneNumber)?.then(value => {
					setShowOTP(value?.data?.role === 'ADMIN');
				});
			});
		}
	}, [values?.phoneNumber]);

	return (
		<div className="w-full">
			<form className="flex flex-col justify-center w-full" onSubmit={handleSubmit(onSubmit)}>
				<Controller
					name="phoneNumber"
					control={control}
					render={({ field }) => (
						<TextField
							{...field}
							className="mb-16"
							type="text"
							error={!!errors.phoneNumber}
							helperText={errors?.phoneNumber?.message}
							label={t('Phone Number')}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<Icon className="text-20" color="action">
											user
										</Icon>
									</InputAdornment>
								)
							}}
							variant="outlined"
						/>
					)}
				/>

				<Controller
					name="password"
					control={control}
					render={({ field }) => (
						<TextField
							{...field}
							className="mb-16"
							label={t('Password')}
							type="password"
							error={!!errors.password}
							helperText={errors?.password?.message}
							variant="outlined"
							InputProps={{
								className: 'pr-2',
								type: showPassword ? 'text' : 'password',
								endAdornment: (
									<InputAdornment position="end">
										<IconButton onClick={() => setShowPassword(!showPassword)}>
											<Icon className="text-20" color="action">
												{showPassword ? 'visibility' : 'visibility_off'}
											</Icon>
										</IconButton>
									</InputAdornment>
								)
							}}
							required
						/>
					)}
				/>

				{showOTP && (
					<Controller
						name="oneTimePassword"
						control={control}
						render={({ field }) => (
							<TextField
								{...field}
								className="mb-16"
								error={!!errors.oneTimePassword}
								helperText={errors?.oneTimePassword?.message}
								label={t('Код подтверждения')}
								InputProps={{
									inputProps: {
										maxLength: 6,
										style: { '-moz-appearance': 'textfield', appearance: 'textfield' } // Hide number input controls
									},
									endAdornment: (
										<InputAdornment position="end">
											<Icon className="text-20" color="action">
												user
											</Icon>
										</InputAdornment>
									)
								}}
								variant="outlined"
							/>
						)}
					/>
				)}

				<Button
					type="submit"
					variant="contained"
					color="primary"
					className="w-full mx-auto mt-16"
					aria-label="LOG IN"
					disabled={
						_.isEmpty(dirtyFields) ||
						!isValid ||
						login.loading ||
						!(showOTP ? values?.oneTimePassword : true)
					}
					value="legacy"
				>
					{t('Login')}
				</Button>
			</form>
		</div>
	);
}

export default JWTLoginTab;

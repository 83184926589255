class Promisfy {
	status = 0; // 0 pending, 1 finished, 2 failed

	constructor() {
		this.promise = new Promise((resolve, reject) => {
			console.log('Declare Promise');
			this._reject = reject.bind(this.promise);
			this._resolve = resolve.bind(this.promise);
		});
	}

	resolve(value) {
		console.log('resolved');
		this.status = 1;
		this._resolve(value);
	}

	reject(reason) {
		console.log('rejected');
		this.status = 2;
		this._reject(reason);
	}

	wait() {
		return this.promise;
	}
}

export default Promisfy;

export const setXSRFToken = xsrfToken => {
	if (!xsrfToken) return;

	const tokens = JSON.parse(localStorage.getItem('xsrf-tokens') || '{}');
	const account = JSON.parse(sessionStorage.getItem('activeAccount'));

	if (account) {
		tokens[account.id] = xsrfToken;
		localStorage.setItem('xsrf-tokens', JSON.stringify(tokens));
	}
};
